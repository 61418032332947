/**
 * ReceiptScreen component displays a list of receipts with options to edit, delete, and view details.
 *
 * @component
 * @example
 * return (
 *   <ReceiptScreen />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @description
 * This component fetches and displays the user's receipts. It provides functionalities to edit, delete, and view receipt details.
 * It also handles pagination and displays alerts based on user actions.
 *
 * @requires react
 * @requires react-redux
 * @requires react-bootstrap
 * @requires react-router-dom
 * @requires moment
 * @requires ../actions/receiptActions.js
 * @requires ../components/Loader
 * @requires ../components/Paginate.js
 * @requires ../constants/receiptConstants
 *
 * @function
 * @name ReceiptScreen
 *
 * @property {boolean} selected - State to track if a receipt is selected.
 * @property {object} alert - State to manage alert visibility, message, and variant.
 * @property {boolean} alert.show - Indicates if the alert is visible.
 * @property {string} alert.message - The alert message.
 * @property {string} alert.variant - The alert variant (e.g., 'success', 'info').
 * @property {string} keyword - URL parameter for search keyword.
 * @property {number} pageNumber - URL parameter for the current page number.
 *
 * @function showAlert
 * @description Displays an alert with a specified message and variant for a given duration.
 * @param {string} message - The alert message.
 * @param {string} [variant='success'] - The alert variant.
 * @param {number} seconds - Duration in milliseconds for which the alert is displayed.
 *
 * @function deleteHandler
 * @description Handles the deletion of a receipt.
 * @param {string} rimgid - The ID of the receipt to be deleted.
 *
 * @function editHandler
 * @description Handles the editing of a receipt.
 * @param {string} id - The ID of the receipt to be edited.
 *
 * @function useEffect
 * @description Fetches receipts and handles navigation based on user login status and receipt count.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} navigate - React Router navigate function.
 * @param {object} userInfo - User information from Redux state.
 * @param {boolean} success - Indicates if the receipt deletion was successful.
 * @param {string} keyword - Search keyword.
 * @param {number} countRecpt - Count of receipts.
 * @param {number} pageNumber - Current page number.
 *
 * @function useEffect
 * @description Displays an alert if no receipts are found and navigates to the receipts page.
 * @param {function} navigate - React Router navigate function.
 * @param {number} count - Count of receipts.
 * @param {object} alert - Alert state.
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Button, Alert, Form } from 'react-bootstrap'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Loader from '../components/Loader'

import {
  getMyReceipts,
  deleteReceipt,
  getReceipt
} from '../actions/receiptActions.js'
import Paginate from '../components/Paginate.js'

import moment from 'moment'
import { CATEGORY_RECEIPTS_RESET } from '../constants/receiptConstants'

let deleteMultireceipts = []

const ReceiptScreen = () => {
  const [selected, setSelected] = useState(true)
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    variant: 'success'
  })

  const { keyword } = useParams() || ''
  const { pageNumber } = useParams() || 1

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const myReceipts = useSelector((state) => state.myReceipts)
  const { receipts, loading, pages, page, count } = myReceipts

  const deleteReceipts = useSelector((state) => state.deleteReceipts)
  const { success } = deleteReceipts

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const countRcpt = useSelector((state) => state.countRcpt)
  const { countRecpt } = countRcpt

  // Change handler
  function showAlert(message, variant = 'success', seconds) {
    setAlert({
      show: true,
      message,
      variant
    })

    setTimeout(() => {
      setAlert({
        show: false,
        message: '',
        variant: 'success'
      })
    }, seconds)
  }

  // Event handlers
  const deleteHandler = (rimgid) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteReceipt(rimgid))
      showAlert('Receipt Deleted', 'success', 1000)
    }
  }

  const editHandler = (id) => {
    dispatch(getReceipt(id))
    navigate(`/receipts/edit/${id}`)
  }

  const selectHandler = (rimgid) => {
    // Check if receipt is already selected
    const index = deleteMultireceipts.indexOf(rimgid)

    // If selected, add to deleteMultireceipts
    if (index === -1) {
      deleteMultireceipts.push(rimgid)
      setSelected(false)
    } else {
      deleteMultireceipts.splice(index, 1)

      if (deleteMultireceipts.length === 0) {
        setSelected(true)
      }
    }
  }

  // Delete multiple receipts
  const deleteMultiHandler = () => {
    if (window.confirm('Are you sure')) {
      deleteMultireceipts.map((receipt) => {
        dispatch(deleteReceipt(receipt))
        showAlert('Receipts Deleted', 'success', 1000)
        deleteMultireceipts = []
        return deleteMultireceipts
      })
    }
  }

  useEffect(() => {
    if ((userInfo || success) && countRecpt > 0) {
      dispatch(getMyReceipts(pageNumber, keyword))
      dispatch({ type: CATEGORY_RECEIPTS_RESET })
    } else {
      navigate('/dashboard')
    }
  }, [dispatch, navigate, userInfo, success, keyword, countRecpt, pageNumber])

  useEffect(() => {
    if (count === 0) {
      showAlert('No Receipts Found', 'info', 1000)
      navigate('/receipts')
    }
  }, [navigate, count, alert])

  return (
    <Container className='my-2' fluid>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      {loading && <Loader />}
      {receipts && !loading && <Paginate pages={pages} page={page} />}

      <Row>
        {!loading &&
          receipts &&
          receipts.map((receipt) => (
            <Col key={receipt._id} sm={12} md={6} lg={4} xl={3}>
              <Card className='my-3 p-3 rounded' style={{ width: '18rem' }}>
                <Card.Header className='bg-secondary'>
                  <Button
                    className='btn btn-sm'
                    onClick={() =>
                      navigate(`/category`, {
                        state: { category: receipt.rcategory }
                      })
                    }
                  >
                    {receipt.rcategory} <i className='fas fa-tag'></i>
                  </Button>
                </Card.Header>

                <Card.Body>
                  <Card.Img
                    src={`/static/${receipt.qfname}`}
                    variant='top'
                    alt=''
                  />
                  <Link to={`/receipts/${receipt.rimgid}`}>
                    <br />
                    <Card.Title as='div'>
                      {moment(receipt.createdAt).format(
                        'ddd DD MMM YYYY h:mm A'
                      )}
                    </Card.Title>
                  </Link>
                  <Card.Text as='div'>{receipt.rcptext}</Card.Text>
                </Card.Body>

                <Card.Footer>
                  <Button
                    variant='primary'
                    block='true'
                    onClick={() => editHandler(receipt._id)}
                  >
                    <i className='fas fa-edit'></i>
                  </Button>{' '}
                  <Button
                    variant='danger'
                    block='true'
                    onClick={() => deleteHandler(receipt.rimgid)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                  <br />
                  <Form.Check
                    type='checkbox'
                    label='Select'
                    onClick={(e) => selectHandler(receipt.rimgid)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
      {selected === false && deleteMultireceipts.length > 0 && (
        <Row>
          <Col>
            <Button
              className='btn btn-md btn-block btn-danger my-2 mx-auto'
              onClick={() => deleteMultiHandler()}
            >
              <i className='fas fa-trash'></i> Delete all Selected Receipts
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default ReceiptScreen
